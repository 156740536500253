<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Өнгөний жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/color-add">
                                    <el-button type="success" class="item">Нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                          <el-dropdown>
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="colorList"
                            style="width: 100%"
                            v-loading="isLoading"
                            @cell-click="clickCell"
                            >
                            <el-table-column label="Өнгө">
                              <template slot-scope="scope">
                                    <div class="image-holder" :style="`background-color:${scope.row.hex_code};width:80px; heigth:64px`" >
                                    </div>
                              </template>
                            </el-table-column>
                            <el-table-column label="Монгол нэр"
                              prop="name_mon"
                            ></el-table-column>
                            <el-table-column label="Англи нэр"
                              prop="name_eng"
                            ></el-table-column>
                            <el-table-column label="Өнгөний код"
                              prop="hex_code"
                            ></el-table-column>
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page.sync="currentPage"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      search: '',
      colorList: [],
      activeTabStatus: 'all',
      isLoading: false,
      pageSize: 20,
      totalCount: 0,
      currentPage: 1,
      dropdownDataFilter: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },

  created () {
    let from = (this.pageSize * (this.currentPage - 1))
    let size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getColors(this.search, from, size)
  },

  methods: {
    onSearch (searchText) {
      this.search = searchText
      this.$router.push({ name: 'colorList', query: { search: this.search, page: this.currentPage, size: this.pageSize } }).catch(() => {})
      this.getColors(this.search, (this.pageSize * (this.currentPage - 1)), this.pageSize)
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'colorList', query: { search: this.search, page: this.currentPage, size: this.pageSize } }).catch(() => {})
      this.getColors(this.search, (this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'colorList', query: { search: this.search, page: item, size: this.pageSize } }).catch(() => {})
      this.getColors(this.search, (this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    getColors (search, from, size) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getColors(query).then(data => {
        this.colorList = data.datas
        this.totalCount = data.total
        this.isLoading = false
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'editColor',
          params: {
            color_id: row.color_id
          },
          query: { search: this.search, page: this.currentPage, size: this.pageSize }
        }
      )
    }
  }
}
</script>

<style>

</style>
